import { Button, Drawer } from "@global";
import Header from "@shared/header";
import { useStore } from "@state/store";
import { Group } from "@state/types";
import { useAdminHooks } from "@util/adminHooks";
import { colors } from "@util/constants";
import { isBrowser } from "@util/helper";
import { Container, Form, GridContainer, PageWidth } from "@util/standard";
import React, { FormEvent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

const Admin = () => {
  const { user } = useStore();
  if (!Boolean(user?.isAdmin))
    return (
      <Container>
        <h1>Opps, looks like you shouldn't be here.</h1>
      </Container>
    );
  const [state, setState] = useState(0);
  const [drawVisible, setDrawVisible] = useState(false);

  const { groups, createGroup, loadAllGroups } = useAdminHooks();

  const handleCreateGroup = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!e.currentTarget?.elements) return;

    const { groupName, admin, seats, school } = e.currentTarget.elements as any;
    const newGroup = {
      schoolName: school.value?.trim() ?? "",
      groupName: groupName.value?.trim() as string,
      admin: admin.value?.trim() as string,
      seats: seats.value as string,
      groupMembers: [],
      createdAt: new Date(),
      users: [],
    };

    const memberRegex = /^member\d+$/;

    // Loop through all elements in the form
    for (let i = 0; i < e.currentTarget.elements.length; i++) {
      const element = e.currentTarget.elements[i];
      if (memberRegex.test(element.name)) {
        if (element.value) {
          newGroup.groupMembers.push(element?.value?.trim());
        }
      }
    }

    const setGroup = await createGroup(newGroup);

    if (setGroup?.success) {
      setDrawVisible(false);
      toast.success("Group Created");
      loadAllGroups();
    }
  };

  const handleDrawVisible = () => {
    setDrawVisible(true);
  };

  return (
    <StyledAdminWrapper>
      <Header state={state} setState={setState} />
      <PageWidth flexDirection="column" width="90%">
        <Container flexDirection="column" className="group-info" width="100%" margin="50px 0">
          <h1 className="admin-title">Admin Dashboard</h1>
          <div className="filter-bar">
            <h4>Groups</h4>
            <Button buttonTheme="orange" linkText="Create Group" onClick={handleDrawVisible} />
          </div>
        </Container>
        <Container flexDirection="column">
          <table>
            <thead>
              <tr>
                <th>School</th>
                <th>Group Name</th>
                <th>Admin Email</th>
                <th>Number of seats</th>
                <th>Number of members</th>
                <th>Activation Code</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {groups?.map((group: Group) => {
                const handleCopyToClip = (link: string) => {
                  console.log(group?.licenseKey?.key);

                  if (!isBrowser()) return;
                  const linkString = `${process.env.GATSBY_BASE_URL}/?register=true&activation_code=${group?.licenseKey?.key}`;
                  navigator.clipboard.writeText(linkString);
                  toast.success("Copied to clipboard");
                };

                return (
                  <tr>
                    <td>{group.schoolName}</td>
                    <td>{group.groupName}</td>
                    <td>{group.admin}</td>
                    <td>{group.seats}</td>
                    <td>{group?.users?.length}</td>
                    <td>{group?.licenseKey?.key}</td>
                    <td>
                      <Button
                        buttonTheme="orange"
                        linkText="Copy Link"
                        onClick={handleCopyToClip}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Container>
      </PageWidth>
      <Drawer
        visible={drawVisible}
        onClose={() => {
          setDrawVisible(false);
        }}
        width="400px"
      >
        <CreateGroup onSubmit={handleCreateGroup} />
      </Drawer>
    </StyledAdminWrapper>
  );
};

export default Admin;

interface CreateGroupProps {
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
}

const CreateGroup = ({ onSubmit }: CreateGroupProps) => {
  const [memberInputs, setMemberInputs] = useState([]);

  const addMemberInput = (e: any) => {
    e.preventDefault();
    setMemberInputs([
      ...memberInputs,
      <input
        key={memberInputs.length}
        type="text"
        placeholder={`Member ${memberInputs.length + 1} Email`}
        name={`member${memberInputs.length + 1}`}
      />,
    ]);
  };

  return (
    <div>
      <Form onSubmit={onSubmit}>
        <GridContainer repeat={1} margin="0 0 15px" columnGap={15} rowGap={15} width="100%">
          <input type="text" name="school" placeholder="School name" />
          <input type="text" name="groupName" placeholder="Group Name" />
          <input type="text" name="admin" placeholder="Admin Email" />
          <input type="number" name="seats" placeholder="Number of seats" />
          {memberInputs}
          <a
            href="#"
            onClick={addMemberInput}
            style={{
              display: "block",
              padding: "10px 0",
              fontSize: "14px",
              textDecoration: "underline",
            }}
          >
            Add Member
          </a>
        </GridContainer>
        <Button linkText="Create Group" buttonTheme="orange" />
      </Form>
    </div>
  );
};

const StyledForm = styled.form``;

const StyledAdminWrapper = styled.div`
  background-color: ${colors.dashboardBackground};
  .group-info {
    h1,
    h2 {
      margin: 0;
    }
  }

  .header {
    background-color: #fff;
    width: 100%;
    padding: 30px;
  }

  .admin-title {
    color: ${colors.maroon};
  }

  .filter-bar {
    background-color: ${colors.progressBackground};
    width: 100%;
    padding: 30px;
    border-radius: 20px;
    margin-top: 20px;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .group {
    display: flex;
  }

  td {
    text-align: center;
  }

  tr td {
    padding: 10px; /* Adjust as necessary */
    border: 1px solid ${colors.grey}; /* Add border to visualize the space */
  }

  tr {
    margin-bottom: 10px; /* Adjust as necessary */
  }
`;
