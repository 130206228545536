import { useStore } from "@state/store";
import { Group } from "@state/types";
import { useEffect, useState } from "react";
import { useFirebaseCreateGroup, useFirebaseGetAllGroups } from "./useFirestoreDocFuncs";

function sortGroupsByCreatedAt(groups) {
  return groups.sort((a, b) => {
    // Default dates for items without 'createdAt'
    const defaultDate = new Date(0); // Epoch time, treated as oldest
    const dateA = a.createdAt ? new Date(a.createdAt.seconds * 1000) : defaultDate;
    const dateB = b.createdAt ? new Date(b.createdAt.seconds * 1000) : defaultDate;

    return dateB - dateA; // For descending order; swap dateA and dateB for ascending
  });
}

export const useAdminHooks = () => {
  const { user } = useStore();
  const [groups, setGroups] = useState<Group[] | null>(null);

  const loadAllGroups = async () => {
    const allGroups = await useFirebaseGetAllGroups();
    if (allGroups) {
      setGroups(sortGroupsByCreatedAt(allGroups as Group[]));
    }
  };

  const createGroup = async (group: any) => {
    console.log(group);
    if (!group) return;

    const createdGroup = await useFirebaseCreateGroup(group);
    return createdGroup;
  };

  useEffect(() => {
    if (groups == null) {
      setTimeout(() => {
        loadAllGroups();
      }, 1000);
    }
  }, []);

  return { groups, createGroup, loadAllGroups };
};
